<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="col-md-7">
          <form @submit.prevent="submit">
            <div class="form-group">
              <label>Bulk SMS Charge</label>
              <input type="text" v-model="pricing.bulk_sms" placeholder="Bulk SMS Charge" class="form-control">
            </div>
            <div class="form-group">
              <label>Sender ID Registration Charge</label>
              <input type="text" v-model="pricing.sender_id" placeholder="Sender ID Registration Charge" class="form-control">
            </div>
            <div class="form-group text-right">
              <button class="btn btn-primary btn-sm"><i class="lni-save mr-2"></i>Save</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    if(this.user.role != 'admin') {
      this.$router.push('app.overview')
    }

    this.fetch()
  },

  data() {
    return {
      pricing: {}
    }
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.$axios.get(`/api/v1/apps/${this.$route.params.id}/pricing`).then(response => {
        this.$loader.stop()
        this.pricing = response.data
      })
    },

    submit() {
      this.$loader.start()
      this.$axios.post(`/api/v1/apps/${this.$route.params.id}/pricing`, this.pricing).then(response => {
        this.$loader.stop()
        this.pricing = response.data
      })
    }
  },

  computed: {
    user() {
      return this.$store.getters.getAuthUser
    }
  }
}
</script>